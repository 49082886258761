import * as React from "react"
import { Helmet } from 'react-helmet';
import { graphql } from 'gatsby';

import Layout from '../../components/layout';
import ContactForm from '../../components/contactForm';
import Footer from '../../components/footer';

import './news_item.scss';

export default function NewsItem({ data: { markdown } }) {
  const { frontmatter, html } = markdown;
  const { title, topic, date } = frontmatter;

  return (<>
    <Helmet>
      <meta charSet="utf-8" />
      <title>Infocus | Homepage</title>
      <meta name="keywords" content="Infocus, Data-Mining, información, análisis de datos" />
      <meta name="description" content="La información hace la diferencia"/>
      <meta property="og:title" content="Infocus | Homepage"></meta>
      <meta property="og:description" content="Infocus, Data-Mining, información, análisis de datos"></meta>
    </Helmet>

    <Layout>
      <section className="NewsItem">
        <div className="news-item">
          <h1 className="news-topic">{topic}</h1>
          <h2 className="news-title">{title}</h2>
          <div dangerouslySetInnerHTML={{ __html: html }} className="news-content" />
          <p className="news-date">{date}</p>
        </div>
      </section>

      <ContactForm/>
      <Footer/>
    </Layout>
  </>)
}

export const pageQuery = graphql`
  query($id: String!, $language: String!) {
    markdown: markdownRemark(id: { eq: $id }) {
      html
      frontmatter {
        date(formatString: "DD/MM/YYYY")
        topic
        title
      }
    }

    locales: allLocale(filter: {language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
